import React, { Component } from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import axios from 'axios';
import { LocalizeProvider } from 'react-localize-redux';

import './App.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const SignedOut = React.lazy(() => import('./views/Pages/SignedOut'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

axios.defaults.headers.common['Content-Type'] = 'application/json';

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isVerified: false,
    };
  }

  async componentDidMount() {
    let user;

    function isPrimaryEmailVerified(userObject) {
      const primaryEmail = userObject.emailaddress_set.filter((emailObject) => {
        return emailObject.primary === true;
      });
      return primaryEmail[0].verified;
    }

    axios.get('/analytics/rest-auth/user/')
      .then((res) => {
        user = res.data;
        if ('detail' in user) {
          // if no user details can be retrieved, the session is closed
          // and user is not authenticated - user details are cleaned
          this.setState({
            isAuthenticated: false,
            user: {},
          });
        } else if (!isPrimaryEmailVerified(user)) {
          this.setState({
            isAuthenticated: true,
            isVerified: false,
            user,
          });
        } else {
          // User is authenticated
          this.setState({
            isAuthenticated: true,
            isVerified: true,
            user,
          });
        }
      })
      .catch((error) => {
        this.setState({ error });
      });
  }


  render() {
    const { isAuthenticated, isVerified, user } = this.state;
    return (
      <LocalizeProvider>
        <BrowserRouter basename={'/reporting'}>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
              <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
              <Route exact path="/analytics/static/vista-ar.png" render={<Redirect to="/analytics/static/vista-ar.png" />} />
              <Route
                path="/"
                name="Home"
                render={
                  (props) => {
                    if (!isAuthenticated) return <SignedOut />;
                    if (isAuthenticated && !isVerified) {
                      window.location.replace(`/analytics/accounts/confirm-email/`);
                    } else {
                      return <DefaultLayout {...props} user={user} />;
                    }
                  }
                }
              />
              <Route path="*">
                <Redirect to="/404" />
              </Route>

            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </LocalizeProvider>
    );
  }
}


export default App;
